<template>
  <v-container>
    <h2>Агентский договор</h2>

    <PersonalDataForm></PersonalDataForm>
  </v-container>
</template>

<script>
import PersonalDataForm from "@/components/PersonalDataForm";

export default {
  name: "Relation",
  components: {PersonalDataForm},
  computed: {}
};
</script>

<style scoped></style>
