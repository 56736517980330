<template>
  <div>
    <v-row class="text-center">
      <v-col>{{ text }}</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-linear indeterminate color="green"></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      default: 'Загрузка…',
    },
  },
};
</script>
