<template>
  <v-list-item :disabled="!active()">
    <template v-slot:default="{ active }">
      <v-list-item-action>
        <v-icon v-if="!active" color="grey lighten-1">
          mdi-radiobox-blank
        </v-icon>

        <v-icon v-else color="yellow darken-3"> mdi-radiobox-marked</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title
          class="text-wrap"
          v-text="item.subject"
        ></v-list-item-title>
        <span class="rounded blue--text text--darken-4" style="font-size: 13px"
          >действителен с {{ notBeforeFormatted }} по
          {{ notAfterFormatted }}</span
        >
      </v-list-item-content>
    </template>
  </v-list-item>
</template>

<script>
import { formatDateTime } from '@/common/Helper';

export default {
  props: {
    item: {},
  },

  methods: {
    active() {
      const { notBefore, notAfter } = this.item;
      const now = new Date();
      return (
        this.parseDateToTimestamp(notBefore) < +now &&
        this.parseDateToTimestamp(notAfter) > +now
      );
    },

    parseDateToTimestamp(v) {
      if (typeof v === 'number') {
        return v;
      }

      return +Date.parse(v);
    },
  },

  computed: {
    notBeforeFormatted() {
      return formatDateTime(this.item.notBefore);
    },

    notAfterFormatted() {
      return formatDateTime(this.item.notAfter);
    },
  },
};
</script>
