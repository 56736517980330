<template>
  <v-container>
    <h2>Подписание агентского договора</h2>
    <template v-if="signedSuccess">
      Спасибо! Договор успешно подписан. <br>

    </template>
    <template v-else>
      <div>Выберетите действующую подпись. <br>
        ИНН должен совпадать с указанным в договоре
      </div>

      <v-btn
          @click="signClick"
          :disabled="!selected || processing"
          class="buttons mb-4 mt-5"
          :loading="processing"
          color="primary"
      >
        подписать
      </v-btn>
      <simple-progress v-if="processing" text="Запрос данных"/>

      <br>
      <certificate-list v-if="!processing" @selected="selectCertificate" :items="certificates"/>
    </template>

  </v-container>
</template>
<script>
import '@/components/ESign/cadesplugin_api';
import ESignProvider from '@/components/ESign/ESignProvider';
import CertificateList from "@/components/ESign/CertificateList.vue";
import simpleProgress from "@/components/Progress/SimpleProgress.vue";

export default {
  name: "RelationSign",
  components: {
    CertificateList, simpleProgress
  },

  computed: {},
  mounted() {
    if (Array.isArray(this.strings)) {
      this.stringsToSign = this.strings;
    }
    setTimeout(() => {
      this.initProvider();
    }, 1000);
  },

  data: () => ({
    allSigned: true,
    consentToSign: [],
    certificates: [],
    selected: undefined,
    provider: undefined,
    processing: false,
    stringsToSign: [btoa(' ')],
    error: undefined,
    loading: false,
    fileType: 'AGENT_CONTRACT',
    signedSuccess: false,
    // signedSuccess: true, //4dev
  }),
  methods: {
    selectCertificate(value) {
      const isSelected = this.certificates[value] !== undefined;
      if (isSelected) {
        this.selected = this.certificates[value];
      } else {
        this.selected = undefined;
      }
    },
    async initProvider() {
      this.provider = new ESignProvider();
      await this.provider.init();
      await this.provider.updateCertificates();
      this.certificates = [...this.provider.certificates];
    },


    async signClick() {
      this.processing = true;
      await this.$http.post('/api/agent/esign/get-contract').then((r) => r.data)
          .then((stringsToSign) => {
            if (this.selected && stringsToSign) {
              this.provider.signData(stringsToSign.fileData, this.selected?.fingerprint, true).then(
                  signature => {
                    this.$http.post('/api/agent/esign/sign',
                        {
                          signature: signature, fileHash: stringsToSign.fileHash,
                          fileType: this.fileType,
                          fileEntityId: this.$store.state.user.profile.id
                        },
                    )
                        .then(() => {
                          //  ui logic. удачно подписано
                          this.signedSuccess = true;
                          this.processing = true;
                        })
                  }
              )
            }
          })
    },

  }
};
</script>

<style lang="scss">
</style>
