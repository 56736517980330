/* eslint-disable */
export default class ESignProvider {
  providers = {
    javalss: {},
    cryptopro: {},
  };

  certificates = [];

  params = {};

  currentProvider = null;

  constructor(params) {
    if (params) {
      this.params = params;
    }
  }

  GetCertificate_NPAPI = (fingerprint) => {
    const oStore = window.cadesplugin.CreateObject('CAdESCOM.Store');
    const thumbprint = fingerprint
      .split(' ')
      .reverse()
      .join('')
      .replace(/\s/g, '')
      .toUpperCase();
    try {
      oStore.Open();
    } catch (err) {
      return;
    }

    const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
    const oCerts = oStore.Certificates.Find(
      CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );

    if (oCerts.Count == 0) {
      return;
    }
    const oCert = oCerts.Item(1);
    return oCert;
  };

  MakeCadesBesSign_NPAPI = (dataToSign, certObject, setDisplayData) => {
    let errormes = '';

    try {
      cadesplugin.CreateObject('CAdESCOM.CPSigner');
    } catch (err) {
      errormes = 'Failed to create CAdESCOM.CPSigner: ' + err.number;
      alert(errormes);
      throw errormes;
    }

    if (oSigner) {
      oSigner.Certificate = certObject;
    } else {
      errormes = 'Failed to create CAdESCOM.CPSigner';
      alert(errormes);
      throw errormes;
    }

    let oSignedData = {};

    try {
      oSignedData = cadesplugin.CreateObject('CAdESCOM.CadesSignedData');
    } catch (err) {
      alert('Failed to create CAdESCOM.CadesSignedData: ' + err.number);
      return;
    }

    const CADES_BES = 1;
    let Signature;

    if (dataToSign) {
      // Данные на подпись ввели
      oSignedData.ContentEncoding = 1; //CADESCOM_BASE64_TO_BINARY
      if (typeof setDisplayData !== 'undefined') {
        //Set display data flag flag for devices like Rutoken PinPad
        oSignedData.DisplayData = 1;
      }
      oSignedData.Content = dataToSign;
      oSigner.Options = 1; //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
      try {
        Signature = oSignedData.SignCades(oSigner, CADES_BES);
      } catch (err) {
        //errormes = "Не удалось создать подпись из-за ошибки: " + GetErrorMessage(err);
        alert(errormes);
        throw errormes;
      }
    }
    return Signature;
  };

  MakeCadesBesSignDetached_NPAPI = (dataToSign, certObject, setDisplayData) => {
    var errormes = '';

    let oSigner = {};
    try {
      oSigner = cadesplugin.CreateObject('CAdESCOM.CPSigner');
    } catch (err) {
      errormes = 'Failed to create CAdESCOM.CPSigner: ' + err.number;
      alert(errormes);
      throw errormes;
    }

    if (oSigner) {
      oSigner.Certificate = certObject;
    } else {
      errormes = 'Failed to create CAdESCOM.CPSigner';
      alert(errormes);
      throw errormes;
    }

    let oSignedData = {};
    try {
      oSignedData = cadesplugin.CreateObject('CAdESCOM.CadesSignedData');
    } catch (err) {
      alert('Failed to create CAdESCOM.CadesSignedData: ' + err.number);
      return;
    }

    const CADES_BES = 1;
    let Signature;

    if (dataToSign) {
      // Данные на подпись ввели
      oSignedData.ContentEncoding = 1; //CADESCOM_BASE64_TO_BINARY
      if (typeof setDisplayData !== 'undefined') {
        //Set display data flag flag for devices like Rutoken PinPad
        oSignedData.DisplayData = 1;
      }
      oSignedData.Content = dataToSign;
      oSigner.Options = 1; //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
      try {
        Signature = oSignedData.SignCades(oSigner, CADES_BES, true);
      } catch (err) {
        //errormes = "Не удалось создать подпись из-за ошибки: " + GetErrorMessage(err);
        alert(errormes);
        throw errormes;
      }
    }
    return Signature;
  };

  get providersList() {
    return this.providers;
  }

  axiosTransformRequest = function (data, headers) {
    delete headers.common['Authorization'];
    return data;
  };

  init = async () => {
    const providersKeys = Object.keys(this.providers).sort((a, b) => b - a);
    for (const k of providersKeys) {
      switch (k) {
        case 'javalss': {
          if (this.providers.hasOwnProperty('javalss')) {
            try {
              const response = await fetch('https://127.0.0.1:61612/version', {
                method: 'get',
              });
              await response.json();
              // await axios.get('https://127.0.0.1:61612/version', {
              //   responseType: 'json',
              //   transformRequest: this.axiosTransformRequest,
              // });
              this.providers.javalss.enabled = true;
              this.currentProvider = 'javalss';
            } catch (e) {
              this.providers.javalss.enabled = false;
            }
          }
          break;
        }
        case 'cryptopro': {
          if (
            typeof cadesplugin !== 'undefined' &&
            this.providers.hasOwnProperty('cryptopro')
          ) {
            window.cadesplugin.then(
              () => {
                this.providers.cryptopro.enabled = true;
                if (!this.currentProvider) {
                  this.currentProvider = 'cryptopro';
                }
              },
              () => {
                this.providers.cryptopro.enabled = false;
              }
            );
          } else {
            this.providers.cryptopro.enabled = false;
          }
          break;
        }
      }
    }
  };

  getJavaLSSSerificates = async () => {
    const certificates = [];
    try {
      const fetchResponse = await fetch(
        'https://127.0.0.1:61612/certificate/?refresh=true',
        { method: 'get' }
      );
      const response = await fetchResponse.json();
      if (response && Array.isArray(response)) {
        response.forEach((item) => {
          certificates.push({
            fingerprint: item.fingerprint,
            subject:
              'CN="' +
              item.subject.CN +
              '", ' +
              'SN=' +
              item.subject.SN +
              ', ' +
              'G=' +
              item.subject.G +
              ', ' +
              'T=' +
              item.subject.T +
              ', INN=' +
              item.subject.INN,
            notBefore: item.notBefore,
            notAfter: item.notAfter,
            body: item.certificate,
            inn: item.subject.INN,
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
    return certificates;
  };

  getCryptoProCertificates = async () => {
    try {
      const canAsync = !!cadesplugin.CreateObjectAsync;
      if (canAsync) {
        const that = this;
        await cadesplugin.async_spawn(function* () {
          const oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
          if (!oStore) {
            return false;
          }

          try {
            yield oStore.Open();
          } catch (e) {
            console.log(e);
            return;
          }

          let certCnt;
          let certs;

          try {
            certs = yield oStore.Certificates;
            certCnt = yield certs.Count;
          } catch (e) {
            console.log(e);
            return;
          }

          for (let i = 1; i <= certCnt; i++) {
            let cert;
            try {
              cert = yield certs.Item(i);
              let pk = cert.PublicKey();
            } catch (e) {
              console.log(e);
              return;
            }

            const dateObj = new Date();
            try {
              const ValidToDate = new Date(yield cert.ValidToDate);
              const ValidFromDate = new Date(yield cert.ValidFromDate);
              const Validator = yield cert.IsValid();
              const IsValid = yield Validator.Result;
              if (
                dateObj < ValidToDate &&
                (yield cert.HasPrivateKey()) &&
                IsValid
              ) {
              } else {
                continue;
              }
            } catch (e) {
              console.log(e);
            }

            try {
              that.certificates.push({
                fingerprint: yield cert.Thumbprint,
                subject: yield cert.SubjectName,
                notBefore: yield cert.ValidFromDate,
                notAfter: yield cert.ValidToDate,
                body: null,
                inn: undefined,
              });
            } catch (e) {
              console.log(e);
            }
          }
          yield oStore.Close();

          yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          certs = yield oStore.Certificates;
          certCnt = yield certs.Count;
          for (let i = 1; i <= certCnt; i++) {
            let cert = yield certs.Item(i);
            //Проверяем не добавляли ли мы такой сертификат уже?
            let found = false;
            for (let j = 0; j < that.certificates.length; j++) {
              if (
                (yield that.certificates[j].fingerprint) ===
                (yield cert.Thumbprint)
              ) {
                found = true;
                break;
              }
            }
            if (found) continue;
            let oOpt = document.createElement('OPTION');
            let ValidFromDate = new Date(yield cert.ValidFromDate);
            let ValidToDate = new Date(yield cert.ValidToDate);
            let IsValid = ValidToDate > Date.now();

            if (IsValid) {
              try {
                that.certificates.push({
                  fingerprint: yield cert.Thumbprint,
                  subject: yield cert.SubjectName,
                  notBefore: yield cert.ValidFromDate,
                  notAfter: yield cert.ValidToDate,
                  body: null,
                  inn: undefined,
                });
              } catch (e) {
                console.log(e);
              }
            }
          }
          yield oStore.Close();
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  updateCertificates = async (callback, errorCallback) => {
    if (this.currentProvider) {
      switch (this.currentProvider) {
        case 'javalss': {
          this.certificates = await this.getJavaLSSSerificates();
          console.log(this.certificates, 'updateCertificates'); // todo: remove this line
          break;
        }
        case 'cryptopro': {
          await this.getCryptoProCertificates();
          break;
        }
      }
    }
  };

  SignCadesBES_Async_File = (
    data,
    fingerprint,
    callback,
    errorCallback,
    detached = false
  ) => {
    cadesplugin.async_spawn(
      function* (arg) {
        const thumbprint = arg[1]
          .split(' ')
          .reverse()
          .join('')
          .replace(/\s/g, '')
          .toUpperCase();
        let oStore;
        try {
          oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
          yield oStore.Open();
        } catch (err) {
          return;
        }

        const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
        const all_certs = yield oStore.Certificates;
        let oCerts = yield all_certs.Find(
          CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
          thumbprint
        );

        if ((yield oCerts.Count) === 0) {
          yield oStore.Close();
          yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
          const all_certs = yield oStore.Certificates;
          oCerts = yield all_certs.Find(
            CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
            thumbprint
          );
          if ((yield oCerts.Count) === 0) {
            console.log('cert not found'); // todo: remove this line
          }
        }
        const certificate = yield oCerts.Item(1);

        let Signature;
        try {
          let errormes = '';
          let oSigner;
          try {
            oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
          } catch (err) {
            errormes = 'Failed to create CAdESCOM.CPSigner: ' + err.number;
            throw errormes;
          }
          const oSigningTimeAttr = yield cadesplugin.CreateObjectAsync(
            'CADESCOM.CPAttribute'
          );

          const CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
          yield oSigningTimeAttr.propset_Name(
            CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME
          );
          const oTimeNow = new Date();
          yield oSigningTimeAttr.propset_Value(oTimeNow);
          const attr = yield oSigner.AuthenticatedAttributes2;
          yield attr.Add(oSigningTimeAttr);

          const oDocumentNameAttr = yield cadesplugin.CreateObjectAsync(
            'CADESCOM.CPAttribute'
          );
          const CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
          yield oDocumentNameAttr.propset_Name(
            CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME
          );
          yield oDocumentNameAttr.propset_Value('Document Name');
          yield attr.Add(oDocumentNameAttr);

          if (oSigner) {
            yield oSigner.propset_Certificate(certificate);
          } else {
            errormes = 'Failed to create CAdESCOM.CPSigner';
            throw errormes;
          }

          const oSignedData = yield cadesplugin.CreateObjectAsync(
            'CAdESCOM.CadesSignedData'
          );
          const CADES_BES = 1;
          const dataToSign = arg[0]; // fileContent - объявлен в Code.js

          if (dataToSign) {
            // Данные на подпись ввели
            yield oSignedData.propset_ContentEncoding(1); //CADESCOM_BASE64_TO_BINARY
            yield oSignedData.propset_Content(dataToSign);
            yield oSigner.propset_Options(1); //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
            try {
              const StartTime = Date.now();
              Signature = yield oSignedData.SignCades(
                oSigner,
                CADES_BES,
                detached
              );
              const EndTime = Date.now();
              arg[2](Signature);
            } catch (err) {
              console.log(err);

              //errormes = "Не удалось создать подпись из-за ошибки: " + GetErrorMessage(err);
              throw errormes;
            }
          }
        } catch (err) {
          console.log(err);
        }
      },
      data,
      fingerprint,
      callback
    );
  };

  signData = async (data, fingerprint, detached = false) => {
    try {
      const certificate = this.certificates.find(
        (c) => c.fingerprint === fingerprint
      );
      console.log('certificate',certificate);
      console.log( 'data',data);
      if (!certificate || !data) {
        return 'Сертификат или данные для подписи не найдены! EsignProvider';
      }
      if (this.currentProvider) {
        switch (this.currentProvider) {
          case 'javalss': {
            const postData = {
              message: data,
              detached: detached,
              certificate: certificate.body,
            };
            try {
              const fetchResponse = await fetch(
                'https://127.0.0.1:61612/signature',
                {
                  method: 'post',
                  body: JSON.stringify(postData),
                  headers: { 'content-type': 'application/json' },
                }
              );
              const response = await fetchResponse.json();
              return response?.data?.signature || response?.signature;
            } catch (e) {
              return 'Ошибка при подписании данных';
            }
          }
          case 'cryptopro': {
            return new Promise((resolve, reject) =>
              this.SignCadesBES_Async_File(
                data,
                fingerprint,
                (sign) => resolve(sign),
                reject,
                detached
              )
            );
            break;
          }
        }
      }
    } catch (e) {}
  };
}
