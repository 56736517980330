<template>
  <v-container style="zzmax-width: 500px">
    <h2>Профиль</h2>

    <v-snackbar
      v-model="snackbar_success"
      timeout="6000"
      color="success"
      outlined
    >
      Профиль сохранен.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar_success = false">
          x
        </v-btn>
      </template>
    </v-snackbar>

    <v-form
      cl
      class="mt-5"
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="saveProfile"
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Имя"
        required
        @change="saveProfile"
        :error-messages="nameErrors"
      ></v-text-field>
      <v-text-field
        :value="phone"
        label="Телефон"
        required
        prefix="+7"
        type="tel"
        disabled
      ></v-text-field>

      <!--      @change="saveProfile"-->
      <v-text-field
        v-model="email"
        label="E-mail"
        :rules="emailRules"
        required
        :error-messages="nameErrors"
        :disabled="disableEmailField"
        :hint="emailHint"
        persistent-hint
      ></v-text-field>

      <v-row>
        <v-col>
          <v-btn color="primary" class="" small type="submit">
            Сохранить
          </v-btn></v-col
        >
        <v-col class="text-right">
          <v-btn
            @click="clickModifyEmail"
            v-if="disableEmailField"
            outlined
            color="primary"
            class=""
            small
          >
            Изменить e-mail
          </v-btn></v-col
        >
      </v-row>
    </v-form>

    <br />
  </v-container>
</template>

<script>
export default {
  name: "Profile",
  data: () => ({
    snackbar_success: false,
    valid: true,
    checkbox: false,
    nameErrors: [],
    emailErrors: [],
    nameRules: [v => !!v || "Введите имя"],
    emailRules: [
      v => !!v || "E-mail обязателен",
      v => /.+@.+\..+/.test(v) || "Не правильный E-mail адрес"
    ],
    isEmailChange: false,
    isSendConfirm: false,
    allowModifyEmail: false // изменить уже подтвержденный email
  }),

  computed: {
    emailHint() {
      if (this.isEmailChange) {
        return "Сохраните профиль чтобы отправить письмо с подтверждением на e-mail";
      }
      if (this.isSendConfirm) {
        return "На указанный Вами адрес была направлена ссылка. Перейдите по ней, чтобы подтвердить Ваш email";
      }
      if (!this.isEmailConfirm) {
        return "Подтвердите e-mail, чтобы получать уведомления на почту";
      }

      return "";
    },
    // showEmailConfirm() {
    //   return !this.isEmailChange && !this.isEmailConfirm && !this.isSendConfirm;
    // },
    disableEmailField() {
      return this.isEmailConfirm && !this.allowModifyEmail;
    },
    name: {
      get() {
        return this.$store.state.user.name;
      },
      set(value) {
        let user = { ...this.$store.state.user };
        user.name = value;
        this.$store.commit("updateUser", user);
      }
    },
    phone() {
      return this.$store.state.user.phone;
    },
    email: {
      get() {
        return this.$store.state.user.email;
      },
      set(value) {
        // if (!this.isEmailConfirm) {
        this.isEmailChange = true;
        let user = { ...this.$store.state.user };
        user.email = value;
        this.$store.commit("updateUser", user);
        // }
      }
    },
    isEmailConfirm() {
      return this.$store.state.user.isEmailConfirm;
    }
  },
  methods: {
    clickModifyEmail() {
      this.allowModifyEmail = true;
    },
    saveProfile() {
      let that = this;
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("saveProfile", {})
          .then(() => {
            this.snackbar_success = true;
            if (that.isEmailChange) {
              this.isEmailChange = false;
              this.isSendConfirm = true;
            }
          })
          .catch(err => {
            this.addErrors(err.data);
          });
      }
    },
    sendEmailConfirm: function() {
      if (this.$refs.form.validate()) {
        let that = this;
        this.$http
          .get("/agents/user-profile/send-email-confirm")
          .then(() => {
            that.isSendConfirm = true;
          })
          .catch(err => {
            that.emailErrors.push(err.message);
          });
      }
    },
    restoreProfile: function() {
      this.$store.dispatch("user", {}).then(() => {
        this.isEmailChange = false;
        this.isSendConfirm = false;
      });
    },
    addErrors: function(errors) {
      errors.forEach(item => {
        if (item.field === "email") {
          this.emailErrors.push(item.message);
        }
        if (item.field === "name") {
          this.nameErrors.push(item.message);
        }
      });
    },

  }
};
</script>
