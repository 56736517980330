import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import MainFooter from "../layout/MainFooter.vue";
import NavBarLanding from "../layout/NavBarLanding";

import Home from "../views/Home.vue";
import About2 from "../views/About2";
import Rates from "../views/Rates";
import Relation from "../views/Relation";
import Calc from "../views/Calc";
import Call from "../views/Call";
import Feedback from "../views/Feedback";
import Faq from "../views/Faq";
import Login from "@/views/Login";
import SignUp from "@/views/SignUp";

import Profile from "@/views/Profile";
import Dossier from "@/views/Dossier";
import Payments from "@/views/Payments";
import PersonalData from "@/views/PersonalData";
// import ConfirmEmail from "@/views/ConfirmEmail";
import CreateLeasingRequest from "@/views/CreateLeasingRequest";
import Agreement from "@/views/Agreement";
import Agreement_personal from "../views/Agreement_personal";
import RelationSign from "@/views/RelationSign.vue";
import PaymentSign  from "@/views/PaymentSign.vue";
import PaymentUpload from "@/views/PaymentUpload.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: Home,
      footer: MainFooter,
      navBar: NavBarLanding
    },
    meta: {
      landing: true
    }
  },
  {
    path: "/about/:step?",
    name: "About",
    props: true,
    component: About2,
    meta: {
      landing: true
    }
  },

  {
    path: "/rates",
    name: "Rates",
    components: {
      default: Rates,
      footer: MainFooter,
      navBar: NavBarLanding
    }
  },
  {
    path: "/relation",
    name: "Relation",
    component: Relation,
  },
  {
    path: "/RelationSign",
    name: "RelationSign",
    component: RelationSign,
  },
  {
    path: "/calc",
    name: "Calc",
    component: Calc,
  },
  {
    path: "/faq",
    name: "Faq",
    components: {
      default: Faq,
      footer: MainFooter,
      navBar: NavBarLanding
    }
  },
  {
    path: "/login",
    name: "Login",
    components: {
      default: Login,
      footer: MainFooter,
      navBar: NavBarLanding
    }
  },
  {
    path: "/sign-up",
    name: "SignUp",
    components: {
      default: SignUp,
      footer: MainFooter,
      navBar: NavBarLanding
    }
  },

  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dossier",
    name: "Dossier",
    component: Dossier,
    meta: {
      requiresAuth: true
    },
    props: {
      default: () => {
        return { newComments: router.app.$store.getters.newComments };
      }
    }
  },

  {
    path: "/payments",
    name: "Payments",
    component: Payments,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/PaymentSign/:pid",
    name: "PaymentSign",
    props: true,
    component: PaymentSign,

    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/PaymentUpload/:pid",
    name: "PaymentUpload",
    props: true,
    component: PaymentUpload,

    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/personal-data",
    name: "PersonalData",
    component: PersonalData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/create-leasing",
    name: "CreateLeasingRequest",
    component: CreateLeasingRequest,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/edit/:edit_id",
    name: "EditLeasingRequest",
    props: true,
    component: CreateLeasingRequest,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: "/create-leasing-pro",
  //   name: "CreateLeasingRequestPro",
  //   components: {
  //     default: CreateLeasingRequestPro,
  //     footer: MainFooter,
  //     navBar: NavBarLanding
  //   },
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: "/edit-pro/:edit_id",
  //   name: "CreateLeasingRequestPro",
  //   props: true,
  //   component: CreateLeasingRequestPro,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: "/call",
    name: "Call",
    components: {
      default: Call,
      footer: MainFooter,
      navBar: NavBarLanding
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/feedback",
    name: "Feedback",
    components: {
      default: Feedback,
      footer: MainFooter,
      navBar: NavBarLanding
    },
    meta: {
      requiresAuth: false
    }
  },

  {
    path: "/agreement",
    name: "Agreement",
    components: {
      default: Agreement,
      footer: MainFooter,
      navBar: NavBarLanding
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/agreement_personal",
    name: "Agreement_personal",
    components: {
      default: Agreement_personal,
      footer: MainFooter,
      navBar: NavBarLanding
    },
    meta: {
      requiresAuth: false
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
export default router;
