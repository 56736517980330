<template>
  <v-card outlined>
    <v-alert v-if="!items || items.length === 0">
      Нет доступных ЭЦП. Убедитесь что у вас установлен
      <a
        href="https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows"
        target="_blank"
        >КриптоПро ЭЦП Browser plug-in</a
      >
      и запущен
      <a href="https://www.cryptopro.ru/products/csp" target="_blank"
        >Криптопровайдер КриптоПро CSP</a
      >.
      <a href="/cabinet/help-esign">Подробное описание требований</a></v-alert
    >
    <v-list v-if="items.length > 0" two-line dense class="pa-0">
      <v-list-item-group
        @change="handleSelect"
        active-class="green--text"
        class="gray"
      >
        <template v-for="(item, index) in items">
          <certificate-item :key="item.title" :item="item" />
          <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import CertificateItem from './CertificateItem';

export default {
  components: { CertificateItem },
  props: {
    items: [],
  },
  methods: {
    handleSelect(e) {
      this.$emit('selected', e);
    },
  },
};
</script>
