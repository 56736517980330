<template>
  <v-container>
    <h2>Калькулятор &mdash; предварительный расчет</h2>
    <v-form class="mt-6" ref="form" v-model="valid" lazy-validation>
      <v-select
        v-model="segment"
        :items="items"
        label="Выберите тип имущества"
        name="items"
        item-text="name"
        return-object
        outlined
        dense
        :rules="rules.segmentId"
        @change="v => handleItemTypeSelect(v.id)"
      ></v-select>
      <!--      <v-row>-->

      <!--        <v-col cols="3">-->

      <!--        </v-col>-->
      <!--      </v-row>-->
      <template v-if="!isEmptySegment">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="costFormated"
              label="Общая стоимость предметов лизинга"
              :hint="hintCost()"
              required
              outlined
              dense
              append-icon="fa-ruble-sign"
              :rules="[ruleCost]"
              :error-messages="errors.itemCost"
              type="integer"
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="py-0 py-md-3">
            <v-text-field
              outlined
              dense
              label="Количество единиц"
              suffix="шт"
              type="number"
              min="1"
              max="99"
              v-model.number="fields.countItems"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="pt-0 pt-md-3">
            <div class="agentValue">
              {{ advanceSum | formatNumber }} &#8381;
            </div>
            <br />
            <label class="primary--text agentLabel">Авансовый платеж</label>
          </v-col>
        </v-row>
        <div
          class="mt-2 mt-md-0"
          v-if="fields.segmentId !== 11"
          id="advanceSlider"
        >
          <div class="text-subtitle-1">
            Размер аванса, %:
          </div>

          <v-slider
            ticks
            v-model="fields.advance"
            :min="advanceLabels[0]"
            :max="advanceLabels[advanceLabels.length - 1]"
            track-color="primary"
            name="advancePercent"
            thumb-label="always"
            class="align-center mt-8"
            @change="calculateAPI"
          >
            <template v-slot:thumb-label="{ value }"> {{ value }}%</template>
            <template v-slot:prepend> {{ advanceLabels[0] }}%</template>
            <template v-slot:append>
              {{ advanceLabels[advanceLabels.length - 1] }}%
            </template>
          </v-slider>
        </div>
        <div class="text-subtitle-1">Срок лизинга, месяцы:</div>
        <v-slider
          ticks
          v-model="fields.term"
          :min="termLabels[0]"
          :max="termLabels[termLabels.length - 1]"
          track-color="primary"
          name="advancePercent"
          thumb-label="always"
          class="align-center mt-8"
          @change="calculateAPI"
        >
          <template v-slot:thumb-label="{ value }"> {{ value }}м</template>
          <template v-slot:prepend> {{ termLabels[0] }}м</template>
          <template v-slot:append>
            {{ termLabels[termLabels.length - 1] }}м
          </template>
        </v-slider>

        <v-row v-if="isPro">
          <v-col cols="12" md="6" class="py-0 py-md-3"
            ><br />
            <v-select
              class="mt-2"
              v-model="agentPercentPro"
              :items="agentPercentList"
              name="agentproPercent"
              label="Размер агентского вознаграждения"
              outlined
              dense
              suffix="%"
              @change="calculateAPI"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0 py-md-3">
            <span style="font-size: 90%;"
              >База расчета агентского вознаграждения:</span
            >
            <v-radio-group
              row
              class="py-0 py-md-0"
              v-model="fields.agentPaymentBase"
              name="agentPaymentBase"
              @change="calculateAPI"
            >
              <v-radio label="от суммы ДКП" value="1"></v-radio>
              <!--            Calculator::AGENCY_BASE_ITEM_COST-->
              <v-radio
                class="mt-1 mt-md-0"
                label="от суммы финансирования"
                value="0"
              ></v-radio>
              <!--            Calculator::AGENCY_BASE_FINANCING_SUM-->
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col cols="12" md="5" class="">
            <v-row>
              <v-col cols="auto" class=" px-1 py-0"
                >Эффект от лизинга, руб.:</v-col
              >
              <v-spacer
                v-if="$vuetify.breakpoint.mdAndUp"
                class="vspacer"
              ></v-spacer>
              <v-col cols="auto" class="px-1 py-0">{{
                eff | formatNumber
              }}</v-col>
            </v-row>
            <v-row>
              <v-col class="col-auto text-caption px-1 py-0"
                >Сумма НДС к зачету, руб.:
              </v-col>
              <v-spacer
                v-if="$vuetify.breakpoint.mdAndUp"
                class="vspacer"
              ></v-spacer>
              <v-col class="col-auto text-caption px-1 py-0"
                >{{ nds | formatNumber }}
              </v-col>
            </v-row>
            <v-row align="end">
              <v-col
                cols="auto"
                class="text-caption px-1 py-0"
                style="xmax-width: 50%"
                >Экономия по налогу на прибыль, руб.:
              </v-col>
              <v-spacer
                v-if="$vuetify.breakpoint.mdAndUp"
                class="vspacer"
              ></v-spacer>
              <v-col cols="auto" class="text-caption px-1 py-0"
                >{{ nds | formatNumber }}
              </v-col>
            </v-row>
          </v-col>

          <v-col class="col-md-3 pt-5 pt-md-1 pb-0 pb-md-2">
            <div class="agentProcent">
              <div class="agentValue">{{ payment | formatNumber }} &#8381;</div>
              <br />
              <label class="primary--text agentLabel">Ежемесячный платеж</label>
              <br />
            </div>
          </v-col>
          <v-col class="col-md-3 pt-5 pt-md-1 pb-0 pb-md-2">
            <div class="agentProcent">
              <div class="agentValue">{{ percentage | formatNumber }} %</div>
              <br />
              <label class="primary--text agentLabel">
                Годовое удорожание</label
              >
              <br />
            </div>
          </v-col>
        </v-row>

        <v-row class="black--text">
          <v-col></v-col>
        </v-row>
      </template>
    </v-form>

    <v-row style="border: 1px solid gray;border-radius: 15px;">
      <v-col>
        <span style="font-size: 90%;"
          >Определите способ выплаты Агентского вознаграждения:</span
        >
        <v-radio-group v-model="fields.agentType">
          <v-radio
            :label="`Вы Агент в форме Юр. Лицо (ИП  или ООО)`"
            :value="`JUR`"
          ></v-radio>
          <v-radio
            :label="`Вы Агент в форме Физ. Лицо`"
            :value="`FIZ`"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="caption">
        Предпочтительным и наиболее выгодным способом для клиента является
        выплата через Агента - Юридическое лицо, т.к. удорожание графика для
        клиента происходит на меньшую величину. Учитывайте это при формировании
        предварительного предложения клиенту.
      </v-col>
    </v-row>

    <v-row v-if="!isEmptySegment">
      <v-col>
        <v-row no-gutters>
          <v-col cols="1" class="mr-2">
            <v-icon color="primary">fas fa-info-circle</v-icon>
          </v-col>
          <v-col xmd="auto" class="caption">
            Данное предложение не является публичной офертой, действует до
            появления новых или дополнительных условий.
          </v-col>
        </v-row>
      </v-col>
      <v-col style="text-align: right;">
        <v-btn v-bind="size" color="primary" @click="getChart"
          >Сформировать предложение клиенту
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay :value="overlay"></v-overlay>
  </v-container>
</template>

<script>
import ym from "../util/ym";

export default {
  name: "Calc",
  data: () => ({
    valid: true,
    items: [],
    segment: null,
    currentTerm: null,
    currentAdvance: null,
    terms: [13, 18, 24, 30, 36, 42, 48, 54, 60],
    advances: [10, 15, 20, 25, 30, 35, 40, 45, 49],
    advanceSum: 0,
    agentPercentList: [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6],
    agentPercentPro: 2,

    fields: {
      segmentId: null,
      cost: null,
      // cost: 3000000, // 4dev test
      term: null,
      advance: null,
      agentType: "JUR",
      agentPaymentBase: "0",
      countItems: 1
    },
    rules: {
      segmentId: [v => !!v || "Выберите предмет лизинга"],
      cost: [
        v => v !== "" || "Введите стоимость",
        v => !!v || "Введите стоимость",
        v =>
          v.replace(/\s+/g, "") >= 2000000 || "Стоимость не менее 2 млн руб.",
        v =>
          v.replace(/\s+/g, "") <= 70000000 || "Стоимость не более 70 млн руб."
      ]
    },
    errors: {
      itemCost: []
    },
    financingSum: 0,
    payment: 0,
    total: 0,
    nds: 0,
    eff: 0,
    percentage: 0,
    leasingPercent: 0,
    client: "",
    segmentName: "",
    segmentModel: "",
    overlay: false,
    ymData: [
      {
        id: "1",
        name: "Спецтехника (ПСМ)",
        goal: "calc_spectehnica"
      },
      {
        id: "2",
        name: "Сельхозтехника (ПСМ)",
        goal: "calc_selhoztehnica"
      },
      {
        id: "3",
        name: "Сельхозоборудование / навеска",
        goal: "calc_selhozoborudovanie"
      },
      {
        id: "4",
        name: "Железнодорожные вагоны",
        goal: "calc_zhdvagony"
      },
      {
        id: "5",
        name: "Грузовые автомобили и легкий коммерческий транспорт",
        goal: "calc_gruz_auto"
      },
      {
        id: "6",
        name: "Прицепы к грузовым авто",
        goal: "calc_pricep_k_gruz_auto"
      },
      {
        id: "7",
        name: "Оборудование / станки",
        goal: "calc_oborud_stanki"
      },
      {
        id: "8",
        name: "Легковые автомобили",
        goal: "calc_legk_auto"
      },
      {
        id: "9",
        name: "Медицинское оборудование",
        goal: "calc_medoborudvanie"
      },
      {
        id: "11",
        name: "Возвратный лизинг",
        goal: "calc_vozvrat_leasing"
      },
      {
        id: "12",
        name: "Автобусы и микро-автобусы",
        goal: "calc_avtobusy_mickoavtobusy"
      },
      {
        id: "13",
        name: "Подвижной состав",
        goal: "podvizhnoy_sostav"
      }
    ]
  }),
  filters: {
    formatNumber: function(value) {
      if (!value) return 0;
      value = value.toString();
      return value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    }
  },
  computed: {
    isEmptySegment: function() {
      return this.fields.segmentId === null;
    },
    costFormated: {
      set: function(val) {
        this.fields.cost = parseInt(val.replace(/\s+/g, ""));
        this.calculateAPI();
      },
      get: function() {
        if (this.fields.cost === null) {
          return "";
        }
        return (this.fields.cost + "").replace(
          /(\d)(?=(\d\d\d)+([^\d]|$))/g,
          "$1 "
        );
      }
    },
    termMin: function() {
      return this.segment.termMin;
    },
    termMax: function() {
      return this.segment.termMax;
    },
    advanceMin: function() {
      return this.segment.advanceMin;
    },
    termLabels: function() {
      let that = this;
      return this.terms.filter(function(number) {
        return number >= that.termMin && number <= that.termMax;
      });
    },
    advanceLabels: function() {
      let that = this;
      return this.advances.filter(function(number) {
        return number >= that.advanceMin;
      });
    },
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    isPro() {
      return this.$store.state.user.is_pro;
    },
    agentPercent() {
      // про-агенты указывают сами из дропдауна, обычным берем из сегмента
      if (this.isPro) {
        return this.agentPercentPro;
      } else {
        return parseInt(this.segment.agentPercent);
      }
    }
  },
  watch: {
    segment: function(val) {
      this.fields.segmentId = val.id;
      this.currentTerm = 0;
      this.currentAdvance = 0;
      this.calculateAPI();
    },

    "fields.cost": function() {
      this.calculateAPI();
    },
    "fields.agentType": function() {
      this.calculateAPI();
    }
  },
  methods: {
    getItems() {
      let that = this;
      that.$http
        .get("/agents/leasing/items")
        .then(function(res) {
          that.items = res.data;
          that.segment = res.data[0]; //  по умолчанию берем первый сегмент
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    addErrors(errors) {
      errors.forEach(item => {
        this.errors[item.field].push(item.message);
      });
    },
    clearErrors() {
      //очищаем предыдущие ошибки
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = [];
      });
    },
    calculateAPI() {
      const data = {
        itemCost: this.fields.cost,
        advancePayment: Math.round(
          (this.fields.cost * this.fields.advance) / 100
        ),
        term: this.fields.term,
        agentType: this.fields.agentType,
        segmentId: parseInt(this.segment.id),
        agentPercent: this.agentPercent,
        agentPaymentBase: this.fields.agentPaymentBase
      };

      // возвратный лизинг -- аванса не бывает вообще
      if (this.fields.segmentId == 11) {
        this.fields.advance = 0;
        data.advancePayment = 0;
      }
      this.clearErrors();
      this.$http
        .post("/api/open-calculator/calc", data) // new api (1C формула)
        .then(response => {
          this.advanceSum = response.data.advancePayment;
          this.payment = response.data.value;
          this.financingSum = this.fields.cost - this.advanceSum;
          this.leasingPercent = response.data.interestRate * 100;
          this.eff = response.data.eff; //Эффект от лизинга, руб.:
          this.percentage = response.data.percentage; // процент годового удорожания
          this.nds = response.data.nds; //Сумма НДС к зачету, руб.:
          // console.log(response);
        })
        .catch(error => {
          console.log(error);
          // this.addErrors(error.response.data);
        });
    },
    getChart() {
      try {
        this.handleYm("calc_sformirovat_predlozhenie");
      } catch (e) {
        // console.log(e); // todo: remove this line
      }
      let that = this;
      that.overlay = true;
      that.$http
        .post(
          "/agents/leasing/chart",
          {
            segmentId: that.fields.segmentId,
            itemCost: that.fields.cost,
            advancePayment: that.advanceSum,
            term: that.fields.term,
            agentType: that.fields.agentType,
            countItems: that.fields.countItems,
            // TODO
            agentPercent: that.agentPercent,
            agentPaymentBase: that.fields.agentPaymentBase
            // advance: that.fields.advance,
            // financingSum: that.financingSum,

            // client: that.client,
            // segmentName: that.segmentName,
            // segmentModel: that.segmentModel
          },
          {
            responseType: "arraybuffer"
          }
        )
        .then(function(res) {
          let blob = new Blob([res.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Предварительный график погашений.pdf";
          link.click();
        })
        .catch(function(err) {
          console.log(err);
        })
        .finally(function() {
          that.overlay = false;
        });
    },
    handleItemTypeSelect(id) {
      console.log(process.env); // todo: remove this line
      const itemYmData = this.ymData.find(v => +v.id === +id);
      if (itemYmData) {
        this.handleYm(itemYmData.goal);
      }
    },
    handleYm: ym,
    isRealty() {
      // id недвижки
      return [14, 15, 16, 17].includes(parseInt(this.fields.segmentId));
    },
    hintCost() {
      let min = this.segment.costMinFormated;
      let max = this.segment.costMaxFormated;
      return `От ${min} ₽ до ${max} ₽`;
    },

    ruleCost(v) {
      v = v.replace(/\s+/g, "");
      // верхний лимит
      if (v > this.segment.costMax)
        return `Стоимость не более ${this.segment.costMaxFormated} руб.`;

      if (v >= this.segment.costMin) {
        return true;
      } else {
        return `Стоимость не менее ${this.segment.costMinFormated} руб.`;
      }
    }
  },
  mounted() {
    this.getItems();
  }
};
</script>

<style scoped>
.vspacer {
  border-bottom: 1px dotted;
}
</style>
