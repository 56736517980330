<template>
  <v-container>
    <!--    <simple-progress v-if="processing" text="Запрос данных"/>-->

    <h2 class="mb-8">Подписание заявки на выплату №{{ pid }}</h2>


    <template class="mt-7" v-if="signedSuccess">

      <p>Ваше подтверждение выплаты успешно получено. Мы благодарим вас за проделанную работу и сотрудничество!
      </p>
      <p>Агентское вознаграждение будет перечислено в течение 10 рабочих дней. Пожалуйста, ожидайте поступления средств.
      </p>
      <p>Если у вас возникнут вопросы, не стесняйтесь обращаться в нашу поддержку.
      </p>

    </template>
    <template v-else>
      <form @submit.prevent="uploadFile">
        <v-file-input
            name="fileUpload"
            v-model="file"
            label="Выберите файл"
            accept="image/*,.pdf"
            prepend-icon="fa-paperclip"
            outlined
            dense
        ></v-file-input>
        <v-btn type="submit" color="primary" :loading="loading">Загрузить</v-btn>
      </form>


      <br>
    </template>

  </v-container>
</template>
<script>
export default {
  // components: {CertificateList},
  name: "PaymentUpload",
  props: ["pid"],
  computed: {},
  mounted() {

  },

  data: () => ({
    file: null,
    allSigned: true,
    consentToSign: [],
    certificates: [],
    selected: undefined,
    provider: undefined,
    processing: false,
    stringsToSign: [btoa(' ')],
    error: undefined,
    loading: false,
    fileType: 'AGENT_PAYMENT',
    signedSuccess: false,
    // signedSuccess: true, //4dev

  }),

  methods: {
    async uploadFile() {
      if (!this.file) {
        this.$store.commit(
            "showBottomSheetMessage",
            "Выберите файл для загрузки"
        );
        return;
      }

      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('type', this.fileType);
      formData.append('pid', this.pid);

      try {
        const response = await this.$http({
          url: 'agents/files/upload-payment?id=' + this.pid,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response)
        this.signedSuccess = true;
        // this.$store.commit(
        //     "showBottomSheetMessage",
        //     "Файл успешно загружен"
        // );
      } catch (error) {
        // this.$store.commit(
        //     "showBottomSheetMessage",
        //     "Ошибка при загрузке файла"
        // );
        console.error(error);
      } finally {
        this.loading = false;

      }
    }
  }
};
</script>

<style lang="scss">

</style>
