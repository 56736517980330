import {saveAs} from "file-saver";


export function urlToApi(relativeUrl) {
  return `${process.env.VUE_APP_API_URL}${relativeUrl}`;
}

export function urlFromHash(hash) {
  return `${process.env.VUE_APP_API_URL}/cabinet/download-cloud?hash=${hash}`;
}

export function formatDateTime(timestamp) {
  return new Intl.DateTimeFormat('ru-RU', {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(new Date(timestamp));
}

// https://thewebdev.info/2021/05/22/how-ow-to-download-a-file-in-the-browser-with-vue-js/
// для скачивания нужно передать заголовок Authorization Bearer,
// поэтому просто ссылка не работает (перекидывает на логин)
export function saveAxios({url, label}) {
  this.$http({
    url: url,
    method: "GET",
    responseType: "blob"
  }).then(response => {
    saveAs(response.data, label);
  });
}


export function throttle(func, ms) {
  let isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments;
      savedThis = this;
      return;
    }
    isThrottled = true;

    func.apply(this, arguments); // (1)

    setTimeout(function () {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}

export function debounce(func, ms) {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, arguments), ms);
  };
}
